import React, { FC, PropsWithChildren, useEffect, useState } from 'react';
import { initSGConnect, initSGWTWidgetConfiguration, OriginNetworkType, fetchIsFromIntranetNetwork } from 'services/SgConnect';
import { LoadingPage } from '../Loading';
import { configuration } from 'config/constants';
import { InternetNotAuthorized } from 'components/Pages/Error/InternetNotAuthorized';

declare global {
    namespace React {
        namespace JSX {
            interface IntrinsicElements {
                'sgwt-connect': Record<string, never>;
            }
        }
    }
}

const loadScript = (url: string) => new Promise((resolve, reject) => {
    const scriptElement = document.createElement('script');
    scriptElement.type = 'text/javascript';
    scriptElement.src = url;
    scriptElement.async = true;
    scriptElement.onload = resolve;
    scriptElement.onerror = reject;

    document.head.appendChild(scriptElement);
});

export const SgwtProvider: FC<PropsWithChildren> = ({ children }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [originNetwork, setOriginNetwork] = useState<OriginNetworkType>();
    useEffect(() => {
        initSGWTWidgetConfiguration();
        fetchIsFromIntranetNetwork()
            .then(setOriginNetwork);

        loadScript(`${configuration.cdnSharedBaseUrl}/widgets/sgwt-widget-polyfills/sgwt-widget-polyfill-webcomponent.js`)
            .then(() => loadScript(`${configuration.cdnSharedBaseUrl}/widgets/sgwt-connect/v4/sgwt-connect.js`))
            .then(() => initSGConnect())
            .then(() => {
                loadScript(`${configuration.cdnSharedBaseUrl}/widgets/sgwt-account-center/v4/sgwt-account-center.js`);
                loadScript(`${configuration.cdnSharedBaseUrl}/widgets/sgwt-mini-footer/v4/sgwt-mini-footer.js`);
                loadScript(`${configuration.cdnSharedBaseUrl}/widgets/sgwt-help-center/v4/sgwt-help-center.js`);
                loadScript(`${configuration.cdnSharedBaseUrl}/widgets/sgwt-splash-screen/v4/sgwt-splash-screen.js`);
            })
            .then(() => setIsLoading(false));
    }, []);

    return <>
        <sgwt-connect></sgwt-connect>
        {(isLoading || !originNetwork) && <LoadingPage />}
        {(!isLoading && originNetwork === 'intranet') && children}
        {(!isLoading && originNetwork === 'internet') && <InternetNotAuthorized />}
    </>;
};
