
import React from 'react';
import { toast } from 'react-toastify';
import { ToastMessage } from 'components/common/ToastMessage';

export const toastError = (message: string) => {
    toast.error(
        <ToastMessage message={message} />,
        {
            className: 'p-0',
            toastId: message,
        },
    );
};
