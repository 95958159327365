import React, { FC } from 'react';
import SgLogo from 'images/sg_logo.svg';
import { NavLink } from 'react-router-dom';
import { RoutePaths } from 'routes/RoutePaths';

export const HeaderLogo: FC = () => {
    return <NavLink
        end
        to={RoutePaths.Home.url()}
        className="navbar-title-link"
    >
        <svg width="28" height="28">
            <image xlinkHref={SgLogo} x="0" y="0" height="28" width="28"></image>
        </svg>
        <div className="navbar-title-divider"></div>
        <div className="navbar-service-name">My Services Backoffice</div>
    </NavLink>;
};