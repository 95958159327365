import NotAuthorized from 'components/Pages/Error/NotAuthorized';
import { useAppContext } from 'components/common/AppProvider';
import { LoadingPage } from 'components/common/Loading';
import React, { useEffect, FC } from 'react';
import { useHasAccess } from 'services/RightsService';
import { getApplicationContext } from 'store/ApplicationContext/ApplicationContextThunk';

export const ProtectedRoute: FC<{ children: React.JSX.Element }> = ({ children }) => {
    const { dispatch } = useAppContext();
    const { hasAccess, isFetchingOrUnloaded } = useHasAccess();

    useEffect(() => {
        dispatch(getApplicationContext());
    }, []);

    if (isFetchingOrUnloaded) {
        return <LoadingPage />;
    }

    return hasAccess ? children : <NotAuthorized />;
};
