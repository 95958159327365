import React, { FC } from 'react';
import { HeaderLogo } from './HeaderLogo';
import { AccountCenter } from './AccountCenter';
import { HeaderNavigation } from './HeaderNavigation';
import { HelpCenter } from './HelpCenter';
import { SplashScreen } from './SplashScreen';

export const Header: FC = () => {
    return <>
        <header className="sticky-top">
            <nav className="navbar navbar-header navbar-expand-lg navbar-light bg-lvl1 px-3 border-bottom">

                <HeaderLogo />

                <HeaderNavigation />

                <div className="d-block flex-grow-1"></div>

                <AccountCenter />

            </nav>
        </header>
        <HelpCenter />
        <SplashScreen />
    </>;
};
